/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import {
  TaskType,
  ImageSelectionTask,
  ImageSelectionAnswer,
  ImageClickingTask,
  ImageClickingAnswer,
} from 'data-models/task-models'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import {
  Tab,
  Tabs,
  Flex,
  Image,
  VStack,
  HStack,
  Center,
  AspectRatio
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { TaskAdvice } from './Common'
import CheckIcon from 'common/CheckIcon'
import { apiBase } from 'services/config'
import { TaskHeading } from './Common/TaskHeading'
import { useFullscreen } from 'providers/FullscreenProvider'

export default function ImageClickTaskCmp() {
  const { isFullscreen } = useFullscreen()
  const { t, i18n } = useTranslation()
  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as ImageClickingTask
  const [isSelected, setIsSelected] = useState(false)

  //answer options animate when page is rerendered, so to prevent it the clases are removed when rendering.
  useEffect(() => {
    clearAnimation()
  }, [])

  const clearAnimation = () => {
    task.answerOptions.forEach(item => {
      const imageOptionsUnSelected =
        document.getElementsByClassName('image-unselected')
      Array.from(imageOptionsUnSelected).forEach(imageOption => {
        // Remove the 'image-unselected' class from each element
        imageOption.classList.remove('image-unselected')
      })
      if (item.checked) {
        const imageOptionsSelected =
          document.getElementsByClassName('image-clicked')
        Array.from(imageOptionsSelected).forEach(imageOption => {
          // Remove the 'image-clicked' class from each element
          imageOption.classList.remove('image-clicked')
        })
      }
    })
  }

  function onSelectedChange(changed: ImageClickingAnswer['id']) {
    const newOptionsState = task.answerOptions.map(ao => ({
      ...ao,
      checked: ao.id === changed ? !ao.checked : ao.checked,
    }))

    const checkImagesSelected = newOptionsState.filter(
      x => x.checked && x.type === TaskType.ImageClick,
    )
    newOptionsState.map(obj => {
      if (
        checkImagesSelected.length > 0 &&
        obj.type === TaskType.Checkbox &&
        obj.checked
      ) {
        obj.checked = false
      }
      return obj
    })
    dispatch({
      type: QUESTION_ACTIONS.UPDATE_ANSWERS,
      payload: newOptionsState,
    })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }
  const leftPositions = [10, 25, 40, 54, 65, 78]
  return (
      <Flex direction='column' h='full' top={10} w='100%'>
      <VStack p='5' pb='4' mb='2' w='full' h='full' align='center'>
        <TaskHeading
          description={task.description}
          subtitle={task.subtitle}
          headingTitle={task?.title}
        />
        <Flex
          h='full'
          w='full'
          maxW='full'
          justify='center'
          align='center'
          flexDirection='column'
        >
          <HStack
            spacing='5'
            justify='center'
            pt='20px'
            w={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '100%'
                : '100%'
            }
            h={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '50%'
                : '100%'
            }
          >
            <Flex pb='12' h='full' w='full' justify='center' align='center'>
              {task.imageUrls?.map(url => (
                <AspectRatio key={url} ratio={10} h='full' w='full'>
                  <Image
                    src={apiBase + url}
                    textAlign={'center'}
                    borderRadius='xl'
                    style={{
                      width: '90%',
                      margin: '0 auto',
                    }}
                  />
                </AspectRatio>
              ))}

              {task.answerOptions.map(
                (ao, index) =>
                  ao.type === TaskType.ImageClick && (
                    <Center
                      key={ao.id}
                      boxSize='12vh'
                      borderRadius='xl'
                      borderWidth='4px'
                      mx='2'
                      my='2'
                      bottom={'20%'}
                      left={`${leftPositions[index % leftPositions.length]}%`}
                      bgColor={ao.checked ? 'white' : 'transparent'}
                      borderColor={ao.checked ? 'pink.500' : 'transparent'}
                      pos='absolute'
                      cursor='pointer'
                      className={
                        ao.checked ? 'image image-clicked' : 'image-unselected'
                      }
                      onClick={() => onSelectedChange(ao.id)}
                    >
                      {ao.checked && (
                        <Center
                          pos='absolute'
                          left='1'
                          top='1'
                          boxSize='5'
                          bgColor='pink.500'
                          borderRadius='full'
                        >
                          <CheckIcon color='white' />
                        </Center>
                      )}
                      <Image
                        src={apiBase + ao.imageUrl}
                        borderRadius='xl'
                        maxH='70%'
                        maxW='70%'
                        opacity={'1 !important'}
                      />
                    </Center>
                  ),
              )}
            </Flex>
          </HStack>
        </Flex>
        <TaskAdvice
          pb='0'
          pos='relative'
          minH='unset'
          bottom='-2'
          instructions={task.taskInstructions}
        />
      </VStack>
    </Flex>
  )
}
