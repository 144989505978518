/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react'

import Dropdown from 'react-dropdown'
import {
  Text,
  Flex,
  VStack,
  HStack,
  Spinner,
  Button,
  Box,
  GridItem,
  Grid,
  ButtonGroup,
  Stack,
  Input,
  Image,
  useDisclosure,
  Spacer
} from '@chakra-ui/react'
import { AuthService } from 'services/authService'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useWelcome } from './hooks'
import ReadIcon from 'common/ReadIcon'
import { useTranslation } from 'react-i18next'
import GenderPicker from 'common/GenderPicker'
import SelectDatePickerCmpen from 'common/SelectDatePickerCmpen'
import SelectDatePickerCmp from 'common/SelectDatePickerCmp'
import SelectDatePickerCmpDe from 'common/SelectDatePickerCmpDe'
import { AgeType, Child, Gender } from 'data-models'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { useHistory, useLocation } from 'react-router-dom'
import DropdownArrowIcon from 'common/DropdownArrowIcon'
import { AgeSelector } from './FollowUp/components'
import { HelpToolTip } from './FollowUp/HelpToolTip'
import { BsInfoCircleFill } from 'react-icons/bs'
import PreschoolDropdownEn from 'common/PreschoolDropdownEn'
import PreschoolDropdown from 'common/PreschoolDropdown'
import DepartmentDropdown from 'common/DepartmentDropdown'
import DepartmentDropdownEn from 'common/DepartmentDropdownEn'
import FollowupDropdown from 'common/FollowupDropdown'
import FollowupDropdownEn from 'common/FollowupDropdownEn'
import WelcomeInstructionIcon from '../../assets/images/WelcomeInstruction-Icon.svg'
import InstructionModal from './InstructionModal'
export const welcomeInstructionsTitle = 'Att läsa innan ni genomför Myran:'

export default function Welcome() {
  const {
    handleNext,
    loading,
    onPreschoolSelect,
    FollowUpOptions,
    preschoolOptions,
    preschool,
    goToChildInformation,
    selectedFollowupId,
    setSelectedFollowupId,
    handleSelectDepartment,
    departmentId,
    organization,
  } = useWelcome()
  const { replace } = useHistory()
  const { t, i18n } = useTranslation()
  const [gender, setGender] = useState(Gender.girl)
  const [IsSelectedDate, setIsSelectedDate] = useState(false)
  const [birthdate, setBirthdate] = useState('')
  const [age, setAge] = useState<string>('')
  const [childCode, setChildCode] = useState<string>('')
  const { setChild, dispatch, originalTasks } = useFollowUp()
  const logout = () => AuthService.logout()

  const {isOpen, onOpen, onClose} = useDisclosure();
  const location = useLocation();


  const handleChangeAge = (newAge: string) => {
    setAge(newAge)
  }
  const handleChangeChildCode = e => {
    setChildCode(e.target.value)
  }

  const handlechanageId = (id: string) => {
    setSelectedFollowupId(id)
  }
  useEffect(() => {
    if (selectedFollowupId !== '') {
      goToChildInformation()
    }
  }, [selectedFollowupId])




  function removeLanguageSessionStorageItems() {
    const keysToRemove:any = [];
    
    // Collect keys that need to be removed
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.endsWith('-language')) {
        keysToRemove.push(key);
      }
    }
    
    // Remove collected keys
    keysToRemove.forEach(key => sessionStorage.removeItem(key));
  }

  useEffect(() => {
    const rootDiv = document.getElementById('root');
    if (location.pathname === '/welcome') {
      if(rootDiv)
      rootDiv.classList.add('welcome-root'); // Add the class to the root div
    }

    removeLanguageSessionStorageItems();
    


    // Cleanup function to remove the class when the component unmounts or the URL changes
    return () => {
      if(rootDiv)
      rootDiv.classList.remove('welcome-root');
    };
  }, [location]);
 
  function navToTasks() {
    if(!isStartDisabled){
      setChild({ gender, birthdate, age, code: childCode } as Child)
      return replace('/tasks')
    }
  }

  const isStartDisabled =
    !(IsSelectedDate || age) ||
    !selectedFollowupId ||
    !gender || !departmentId ||
    (organization?.showChildCode && !childCode)


console.log('preschoolOptions', preschoolOptions)

  return (
    <Box className="welcome-outer-wrapper">
      <Flex
        direction='column'
        height='600px'
        width='640px'
        className='welcome-wrapper'
        margin='0 auto'
        padding='0'
        // outline='1px solid white'
        justifyContent='space-evenly'
      >
        {/* <Box
          flex={{ base: 'none', md: 1 }}
          backgroundColor='#15073a'
          p={{ base: 4, md: 8 }}
        >
          <HStack mb='2.5' mt={{ lg: 50 }}>
            <ReadIcon></ReadIcon>
          </HStack>
          <HStack mb='10'>
            <Stack spacing={3}>
              <Text fontSize={25}>{t('Instructions')}</Text>
            </Stack>
          </HStack>

          <HStack mb='10'>
            <Text lineHeight='1.5'>{t('The_MainText_WelcomePage')}</Text>
          </HStack>
        </Box> */}

        <Text fontSize={'24px'}>{t('Create_Followup')}</Text>
       
     
           
            <HStack flexWrap='wrap' justifyContent='space-between'>
            <Flex >
              <Box mt={{ base: 4 }}>
                <Flex direction='column' alignItems='flex-start' color='black'>
                  <Box mb={2}>
                    <label htmlFor='preschool'>
                      {t('Preschool_Select_Box') + '  *'}
                    </label>
                  </Box>
                  {i18n.language === 'en' ? (
                    <PreschoolDropdownEn
                      onPreschoolSelect={onPreschoolSelect}
                      preschoolOptions={preschoolOptions.sort((a, b) => String(a.label).localeCompare(String(b.label),'sv'))}
                      preschool={preschool}
                      placeHolder='Preschool'
                      departmentId={departmentId}
                    />
                  ) : 
                  i18n.language === 'de' ? (
                    <PreschoolDropdown
                    onPreschoolSelect={onPreschoolSelect}
                    preschoolOptions={preschoolOptions.sort((a, b) => String(a.label).localeCompare(String(b.label),'sv'))}
                    preschool={preschool}
                    placeHolder='Vorschule'
                    departmentId={departmentId}
                  />
                  )
                  :
                  (
                    <PreschoolDropdown
                      onPreschoolSelect={onPreschoolSelect}
                      preschoolOptions={preschoolOptions.sort((a, b) => String(a.label).localeCompare(String(b.label),'sv'))}
                      preschool={preschool}
                      placeHolder='Förskola'
                      departmentId={departmentId}
                    />
                  )}
                 
                </Flex>
              </Box>
            </Flex>
            <Flex>
              {organization?.ageType === AgeType.BIRTH_DATE ? (
                <Box mt={{ base: 4 }} >
                  {organization?.hasMultipleSections && (
                    <Flex direction='column' alignItems='flex-start'>
                      <Box mb={2}>
                        <label htmlFor='preschool2'>
                          {i18n.language === 'en'
                            ? 'Department  *'
                            :
                            i18n.language === 'de' ?
                            'Abteilung *'
                            : 'Avdelning  *'}
                        </label>
                      </Box>
                      {i18n.language === 'en' ? (
                        <DepartmentDropdownEn
                          preschool={preschool}
                          placeHolder='Select department'
                          departmentId={departmentId}
                          handleNext={handleNext}
                          handleSelectDepartment={handleSelectDepartment}
                        />
                      ) : 
                      i18n.language === 'de' ?
                      (
                        <DepartmentDropdown
                          preschool={preschool}
                          placeHolder='Abteilung auswählen'
                          departmentId={departmentId}
                          handleNext={handleNext}
                          handleSelectDepartment={handleSelectDepartment}
                        />
                      )
                      :
                      (
                        <DepartmentDropdown
                          preschool={preschool}
                          placeHolder='Välj avdelning'
                          departmentId={departmentId}
                          handleNext={handleNext}
                          handleSelectDepartment={handleSelectDepartment}
                        />
                      )}
                  
                    </Flex>
                  )}

                  {loading && <Spinner />}
                </Box>
              ) : (
                <Box mt={{ base: 4 }} ml={{ base: 4, lg: 2 }}>
                  {organization?.hasMultipleSections && (
                    <Flex direction='column' alignItems='flex-start'>
                      <Box mb={2}>
                        <label htmlFor='preschool2'>
                        {i18n.language === 'en'
                            ? 'Department  *'
                            :
                            i18n.language === 'de' ?
                            'Abteilung *'
                            : 'Avdelning  *'}
                        </label>
                      </Box>
                      {i18n.language === 'en' ? (
                        <DepartmentDropdownEn
                          preschool={preschool}
                          placeHolder='Select Department'
                          departmentId={departmentId}
                          handleNext={handleNext}
                          handleSelectDepartment={handleSelectDepartment}
                        />
                      ) : 
                      i18n.language === 'de'
                      ?
                      (
                        <DepartmentDropdown
                          preschool={preschool}
                          placeHolder='Abteilung auswählen'
                          departmentId={departmentId}
                          handleNext={handleNext}
                          handleSelectDepartment={handleSelectDepartment}
                        />
                      )
                      :
                      (
                        <DepartmentDropdown
                          preschool={preschool}
                          placeHolder='Välj avdelning'
                          departmentId={departmentId}
                          handleNext={handleNext}
                          handleSelectDepartment={handleSelectDepartment}
                        />
                      )}
                    </Flex>
                  )}

                  {loading && <Spinner />}
                </Box>
              )}
              
            </Flex>
            </HStack>
            <Flex
            
             
            >
              <Box mr={{ base: 4 }} mt={{ base: '1rem' }}  >
                <Flex direction='column' alignItems='flex-start'>
                  <Box mb={3}>
                    <label htmlFor='preschool3'>
                      {t('Follow_Up_Select_Box') + '  *'}
                    </label>
                  </Box>
                  {i18n.language === 'en' ? (
                    <FollowupDropdownEn
                      selectedFollowupId={selectedFollowupId}
                      handlechanageId={handlechanageId}
                      FollowUpOptions={FollowUpOptions}
                      placeHolder='Follow-up'
                    />
                  ) : 
                  i18n.language === 'de'
                  ?
                  (
                    <FollowupDropdown
                      selectedFollowupId={selectedFollowupId}
                      handlechanageId={handlechanageId}
                      FollowUpOptions={FollowUpOptions}
                      placeHolder='Nachverfolgung'
                    />
                  )
                  :
                  (
                    <FollowupDropdown
                      selectedFollowupId={selectedFollowupId}
                      handlechanageId={handlechanageId}
                      FollowUpOptions={FollowUpOptions}
                      placeHolder='Uppföljning'
                    />
                  )}

                
                </Flex>
              </Box>
            </Flex>
            <Flex
             
           
            >
              <Box>
                <Box mb={3}>
                  <label htmlFor='GenderPicker'>
                    {t('Gender_Field') + '  *'}
                  </label>
                </Box>
                <Box>
                  <GenderPicker onValueChange={setGender} genderSelect={gender}/>
                </Box>
              </Box>
            </Flex>
            <Flex
           
            //  width='100%'
             //   pl={{ base: 5, lg: 5 }}
            >
              {organization?.ageType === AgeType.BIRTH_DATE ? (
                <Box width='100%'>
                  <VStack >
                    <Flex direction='column' alignItems='flex-start' width='100%'>
                      <Box mb={3}>
                        <label>{t('DOB_Field') + '  *'}</label>
                      </Box>
                      {i18n.language === 'en' ? (
                        <SelectDatePickerCmpen
                          IsSelectedChange={setIsSelectedDate}
                          onValueChange={setBirthdate}
                        />
                      ) : i18n.language === 'se' ?
                      (
                        <SelectDatePickerCmp
                          IsSelectedChange={setIsSelectedDate}
                          onValueChange={setBirthdate}
                        />
                      )
                    :
                    (
                      <SelectDatePickerCmpDe
                        IsSelectedChange={setIsSelectedDate}
                        onValueChange={setBirthdate}
                      />
                    )
                    
                    }
                    </Flex>
                  </VStack>
                </Box>
              ) : (
                <VStack
                  minH='0'
                  minW='0'
                //  w='full'
                  justify='center'
                  align='baseline'
                  //pt='5'
                  w='60%'
                //  pl='4'
                >
                  <Text alignSelf='start' fontSize='sm'>
                    {t('Select_Age_Text') + '  *'}
                  </Text>
                  <Box className='age-selector-box'>
                  <AgeSelector age={age} onChange={handleChangeAge} />
                  </Box>
                </VStack>
              )}
              </Flex>
              <Flex>
              {organization?.showChildCode && (
                <VStack
                  minH='0'
                  minW='0'
                  w='full'
                  justify='center'
                  align='center'
                  
                 // pt='5'
                //  pl='4'
                >
                  <HStack w='full' align='center' justify='flex-start'>
                    <Text fontSize='sm'>{t('Child_Alias_Field') + '  *'}</Text>
                    <HelpToolTip
                      title={t('ResultsTable_Child_Alias')}
                      body={t('Child_Alias_Explanation')}
                      image={<BsInfoCircleFill />}
                    />
                  </HStack>
                  <Input
                    w='full'
                    onChange={handleChangeChildCode}
                    placeholder={`${t('Child_Alias_Placeholder')}...`}
                    fontSize='13px'
                    height='44px'
                    className={childCode ? '' : 'addError'}
                  />
                </VStack>
              )}
            </Flex>
            <Flex
            
              justifySelf='end'
              width='640px'
              z-index='990'
            >
              <Flex w='full' justifyContent='space-between'>
                <HStack onClick={onOpen} cursor='pointer'>
                <Image src={WelcomeInstructionIcon} />
                <Text fontSize='13px'>{t('Welcome_Instruction_Text')}</Text>
                </HStack>
                <HStack>
                <Button
                  fontWeight='bold'
                  onClick={logout}
                  height='40px'
                  width='110px'
                  variant='outline'
                  bg=''
                  color='#FF5099'
                  borderColor='#FF5099'
                  opacity={1}
                  _hover={{
                    bg: '',
                    color: '#FF5099',
                    opacity: 1,
                  }}
                >
                  {t('Cancel_Button')}
                </Button>
                <Button
                  fontWeight='bold'
                  ml={5}
                  rightIcon={<ArrowForwardIcon boxSize='5' />}
                  onClick={navToTasks}
                  disabled={isStartDisabled}
                  colorScheme='pink'
                  height='40px'
                  width='110px'
                  variant='outline'
                  bg='#FF5099'
                  color='white'
                  borderColor='#FF5099'
                  opacity={1}
                  _hover={{
                    bg: '',
                    color: 'white',
                    opacity: 1,
                  }}
                >
                  {t('Start_Button')}
                </Button>
                </HStack>
              </Flex>
            </Flex>
  
      
      </Flex>
    <InstructionModal isOpen={isOpen} onClose={onClose}/>
    </Box>
  )
}
