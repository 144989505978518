import React, { useEffect, useState } from 'react'
import {
  Task,
  TaskType,
  CheckboxTask,
  ResultPayload,
  VideoMultiTask,
  ImageSortingTask,
  ImagePlacementTask,
  LanguageAnalysisTask,
  AudioRecordings,
} from 'data-models'
import { TaskResult } from 'data-models/taskresult'
import axiosAPIInstance from './api'
import Tasks from '../locales/Tasks.json'

const TaskService = {

  taskIsAnswered: (task: Task) => {
    if (task.isSkipped) return true
    switch (task.type) {
      case TaskType.Video:
      case TaskType.Audio:
      case TaskType.Checkbox:
      case TaskType.ImageSelection:
        return (task as CheckboxTask).answerOptions.some(a => a.checked)

      case TaskType.ImagePlacement:
        return (task as ImagePlacementTask).answerOptions.every(
          a => !!a.placedAtPoint,
        )
      case TaskType.ImageSorting:
        return (task as ImageSortingTask).answerOptions.every(
          a => !!a.placedAtPoint,
        )

      case TaskType.VideoMulti:
        return (
          (task as VideoMultiTask).answerOptions.a.some(a => a.checked) &&
          (task as VideoMultiTask).answerOptions.b.some(a => a.checked)
        )
      case TaskType.LanguageAnalysis:
        return(
          (task as LanguageAnalysisTask).audioRecorded
        )
      case TaskType.ImageClick:
        return (task as CheckboxTask).answerOptions.some(a => a.checked)
      default:
        return false
    }
  },



  answerSummary: (tasks: Task[]): TaskResult[] => {
  
  const tasksAnswers = tasks.map((task: Task) => {   
  let answersFromArray;
     if(task.type !== 'language_analysis'){
       answersFromArray = answerArr =>   {
        return answerArr.map(answerOption => {
          switch (answerOption.type) {
            case TaskType.ImagePlacement:
              return {
                optionType: answerOption.type,
                correctPoint: answerOption.correctPoint,
                placedAtPoint: answerOption.placedAtPoint,
              }
              case TaskType.LanguageAnalysis:
                return {
                  audioUrl: answerOption.audioUrl,
                  optionId: answerOption.id,
                  optionType: answerOption.type,
                }
            
            default:
              return {
                optionId: answerOption.id,
                optionType: answerOption.type,
                checked: answerOption.checked,
              }
          }
        })
      }
    }
    let collectedAnswers
    if(task.type !== "language_analysis"){
      switch (task.type) { 
        case TaskType.VideoMulti:
          collectedAnswers = {
            a: answersFromArray(task.answerOptions.a),
            b: answersFromArray(task.answerOptions.b),
          }
          break

        default:
          collectedAnswers = answersFromArray(task.answerOptions)
          break
      }
    }
      return {
        taskId: task.id,
        answers: collectedAnswers,
        isSkipped: task.isSkipped,
        skipReason: task.skipReason
      }
    })
    return tasksAnswers
  },


  audioSubmit : (tasks: Task[]) =>{
    const audioArray: AudioRecordings[] = [];

tasks.forEach((task) => {
  if (task.type === 'language_analysis' && task.audioRecordings) {
    task.audioRecordings.forEach((audioRecording) =>{
      const audioObject = {
        audioLength: audioRecording.audioLength,
        audioFile: audioRecording.audioFile,
        taskId: task.id,
        isSkipped: task.isSkipped,
        skipReason: task.skipReason
      };
      
      audioArray.push(audioObject);
    });
  }
});
    return audioArray
  },

canSubmitTasks: (qs: Task[]) => qs.every(q => TaskService.taskIsAnswered(q)),

submitFollowUp: (result: ResultPayload) => {

  const formData = new FormData();

  // Append all properties except audioRecordings to formData
  Object.keys(result).forEach((key) => {
      if (key !== 'audioRecordings') {
          formData.append(key, result[key]);
      }

      if (key === 'audioRecordings' && Array.isArray(result[key])) {
        const audioArray = result[key];
        audioArray.forEach((audio, index) => {
          // Append audioLength
          formData.append(`audioRecordings[${index}].audioLength`, audio.audioLength.toString());
    
          // Append taskId
          formData.append(`audioRecordings[${index}].taskId`, audio.taskId);
         
          // Append audioFile from FormData object
          if(audio.audioFile instanceof Blob){
            formData.append(`audioRecordings[${index}].audioFile`, audio.audioFile,`${index}.wav`);
          }

         //Append isSkipped from FormData object
         formData.append(`audioRecordings[${index}].isSkipped`, audio.isSkipped.toString());

         //Append skipReason from FormData object
         formData.append(
          `audioRecordings[${index}].skipReason`,
          audio?.skipReason?.toString() ?? '' // Provide a default empty string if skipReason is undefined
        );

         //Append audioLanguage 
         const browserLocaleLanguage = navigator.language.split(/[-_]/)[0]
         let siteLanguage
         if (browserLocaleLanguage === 'sv') {
           siteLanguage = 'se'
         } else if(browserLocaleLanguage === 'de'){
           siteLanguage = 'de'
         }
         else{
          siteLanguage = 'en'
         }

         let audioLanguageSelected ;

         if(sessionStorage.getItem('language-selected')){
          audioLanguageSelected = sessionStorage.getItem('language-selected')
         }
         else{
          audioLanguageSelected = siteLanguage;
         }
        
         let audioLanguageFormat ;
         if(audioLanguageSelected === 'se'){
          audioLanguageFormat = 'sv-SE'
         }
         else if(audioLanguageSelected === 'en'){
          audioLanguageFormat = 'en-GB'
         }
         else if(audioLanguageSelected === 'de'){
          audioLanguageFormat = 'de-DE'
         }
         formData.append(`audioRecordings[${index}].audioLanguage`, audioLanguageFormat.toString())
          
        });
      }
        
  });



  return axiosAPIInstance.post(`/followups/${result.followUpId}/result`, formData)
      .then(response => {
          console.log('Follow-up submitted successfully:', response);
          return response; 
      })
      .catch(error => {
          console.error('Error submitting follow-up:', error);
          throw error; 
      });
},



  getAnsweredCount: (qs: Task[]) =>
    qs.filter(TaskService.taskIsAnswered).length,
}

export { TaskService }

export const useTaskService = () => {

  const [tasks, setTasks] = useState<Task[]>([])
  const [tasksEn, setTasksEn] = useState<Task[]>(Tasks as [])
  // const getTasks = () =>
  //   axiosAPIInstance.get('/tasks').then(({ data }) => data as Task[])

  // const refreshTasks = async () => {
  //   const response = await getTasks()
  //   if (response && response.length > 0) {
  //     setTasks(tasksEn)
  //   }
  // }
  // useEffect(() => {
  //   refreshTasks()
  // }, [])
  useEffect(() => {
    setTasks(tasksEn)
  })

  return { tasks }
}
