export enum TaskType {
  Audio = 'audio',
  Video = 'video',
  VideoMulti = 'video_multi',
  ImageSorting = 'image_sorting',
  Checkbox = 'checkbox_selection',
  ImagePlacement = 'image_placement',
  ImageSelection = 'image_selection',
  LanguageAnalysis = 'language_analysis',
  ImageClick = 'image_click',
}

export interface Task {
  id: string
  orderIndex: number
  type: TaskType
  title: string | any
  subtitle?: string
  jsonValue?: string
  taskInstructions: string
  description: string
  answerOptions
  isSkipped: boolean
  step: number
  name: string
  criteria: string
  audioRecordings:AudioRecordings[]
  audioRecorded?:boolean,
  skipReason?: string
  knowledge:string
}

 export interface AudioRecordings{
  audioFile:Blob | string
  audioLength:Number
  taskId:string
  isSkipped: boolean
  skipReason?:string
  audioLanguage?: string | null
}

export interface CheckboxTask extends Task {
  type: TaskType.Checkbox
  imageUrl?: string
  soundUrl?: string
  images?: { url: string; checked: boolean; soundUrl: string }[]
  answerOptions: CheckboxAnswer[]
  answerInstructions: string,
  imageView ?: string
}
export interface CheckboxAnswer {
  id: string
  type: TaskType.Checkbox
  text: string
  checked: boolean
  styleClass?: string
}

export interface AudioTask extends Task {
  type: TaskType.Audio
  audioUrl: string
  answerOptions: CheckboxAnswer[]
  answerInstructions: string
}

export interface VideoTask extends Task {
  type: TaskType.Video
  videoUrl: string
  answerOptions: CheckboxAnswer[]
  answerInstructions: string
}

export interface VideoMultiTask extends Task {
  type: TaskType.VideoMulti
  title: MultiTaskTitle
  videoUrl: string
  answerOptions: MultiTaskAnswer
  answerInstructions: string
}
export interface MultiTaskTitle {
  a: string
  b: string
}
export interface MultiTaskAnswer {
  a: CheckboxAnswer[]
  b: CheckboxAnswer[]
}

export interface PlacementPoint {
  id: string
  x: number
  y: number
}

export interface ImagePlacementTask extends Task {
  type: TaskType.ImagePlacement
  imageUrl: string
  placementPoints: PlacementPoint[]
  answerOptions: ImagePlacementAnswer[]
}
export interface ImagePlacementAnswer {
  type: TaskType.ImagePlacement
  imageUrl: string
  correctPoint: PlacementPoint['id']
  placedAtPoint: PlacementPoint['id'] | null
  styleClass?: string
  text?: string
  checked?: boolean
}

export interface ImageSortingAnswer {
  type: TaskType.ImagePlacement
  correctPoint: PlacementPoint['id']
  placedAtPoint: PlacementPoint['id'] | null
  imageUrl?: string
  styleClass?: string
  text?: string
  checked?: boolean
}

export interface ImageSelectionTask extends Task {
  type: TaskType.ImageSelection
  imageUrls: string[]
  videoUrls: string[]
  answerOptions: ImageSelectionAnswer[]
}
export interface ImageSelectionAnswer {
  id: string
  type: TaskType.ImageSelection | TaskType.Checkbox
  imageUrl: string
  soundUrl: string
  checked: boolean
  text?: string
  styleClass?: string
}


export interface ImageClickingTask extends Task {
  type: TaskType.ImageClick
  imageUrls: string[]
  videoUrls: string[]
  answerOptions: ImageClickingAnswer[]
}
export interface ImageClickingAnswer {
  id: string
  type: TaskType.ImageClick | TaskType.Checkbox
  imageUrl: string
  checked: boolean
  text?: string
  styleClass?: string
}

export interface ImageSortingTask extends Task {
  type: TaskType.ImageSorting
  placementPoints: PlacementPoint[]
  imageUrl?: string
  images: any[]
  answerOptions: ImageSortingAnswer[]
  // answerInstructions: string
}


export interface LanguageAnalysisTask extends Task {
  type: TaskType.LanguageAnalysis,
  imageUrl: string,
  answerOptions: LanguageAnalysisAnswer[],
  audioRecorded: boolean
  audioFiles: FormData
}

export interface LanguageAnalysisAnswer{
  id: string
  type: TaskType.LanguageAnalysis
  audioUrl: FormData
  checked: boolean
  text?: string
  styleClass?: string
}